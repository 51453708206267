import { ApolloClientContext } from '@/contexts/ApolloClientProvider';
import { AppUserContext } from '@/contexts/AppUserProvider';
import {
    useBeginHealthServiceSignupMutation,
    useAuthenticateMutation,
    useLogoutMutation,
    useUserInfoQuery,
    useHealthServiceSignupMutation,
    HealthServiceSignupInput,
    useChangePasswordMutation,
    UserInfoDocument,
    useChangeEmailMutation,
    useVerifySmsPhoneNumberMutation,
    useChangeSmsNumberMutation,
    useResendVerificationSmsMutation,
    CustomerInfoOutput,
} from '@/graphql/hooks';
import { getApolloClient } from 'apollo-client';
import { E164Number } from 'libphonenumber-js';
import { useContext } from 'react';
import { COOKIE_KEY } from 'types/security';
import { useTranslation } from 'react-i18next';
import { getCookie, removeCookie, setCookie } from './cookies.service';
import { GetServerSidePropsContext } from 'next';
import { HealthServiceVerificationStatus } from '@/types/user';
//import { captureException } from '@sentry/nextjs';

export const verifiedHealthServiceStatuses: HealthServiceVerificationStatus[] =
    ['verified', 'notRequired'];

export const ssrGetUserAndToken = async (
    context: GetServerSidePropsContext,
) => {
    const jwtToken = getCookie(context.req.headers.cookie, COOKIE_KEY);

    const redirectUrl = context.resolvedUrl
        ? `/sign-in?redirectTo=${context.resolvedUrl}`
        : '/sign-in';

    if (!jwtToken) {
        return {
            props: {
                redirect: redirectUrl,
            },
        };
    }

    try {
        const apolloClient = getApolloClient(jwtToken);
        const { data } = await apolloClient.query({
            query: UserInfoDocument,
        });

        const user = data.userInfo as CustomerInfoOutput;

        const healthServiceVerified = verifiedHealthServiceStatuses.includes(
            user.healthServicePatientAccount
                .healthServiceVerificationStatus as HealthServiceVerificationStatus,
        );

        const allowed = healthServiceVerified && user.email;

        if (!allowed) {
            if (context.resolvedUrl !== '/home') {
                return {
                    props: {
                        redirect: '/home',
                        jwtToken,
                        userInfo: data.userInfo,
                    },
                };
            }
        }

        return {
            props: {
                jwtToken,
                userInfo: user,
            },
        };
    } catch {
        return {
            props: {
                redirect: redirectUrl,
            },
        };
    }
};

export const ssrWebsiteUserData = async (
    context: GetServerSidePropsContext,
) => {
    const ssrData = await ssrGetUserAndToken(context);

    if (ssrData.props.userInfo) {
        return {
            ...ssrData,
        };
    }

    return {
        props: {},
    };
};

export const useRefreshUser = (): {
    refreshUserInfo: () => Promise<void>;
} => {
    const { user, setUser } = useContext(AppUserContext);
    const { signOut } = useAuth();
    const { refetch: getUserInfo } = useUserInfoQuery({
        fetchPolicy: 'network-only',
        skip: true,
    });

    const refreshUserInfo = async () => {
        try {
            const response = await getUserInfo();

            setUser({
                ...user,
                ...response.data.userInfo,
            });
        } catch (error) {
            await signOut(false);
            throw new Error('User not authenticated');
        }
    };

    return { refreshUserInfo };
};

interface IUseAuth {
    signIn: (emailAddress: string, password: string) => Promise<void>;
    federatedSignIn: (healthService: string) => void;
    signOut: (showFullscreenLoading?: boolean) => Promise<void>;
    signUp: (emailAddress: string) => Promise<IUseAuthSignup>;
    completeSignUp: (input: HealthServiceSignupInput) => Promise<void>;
}

interface IUseAuthSignup {
    success: boolean;
    errorCode?: 'GenericExeption' | 'UsernameExistsException';
}

export const useAuth = (): IUseAuth => {
    const { t } = useTranslation();
    const { setUser } = useContext(AppUserContext);
    const { setAuthToken } = useContext(ApolloClientContext);
    const { enableFullscreenLoading, disableFullscreenLoading } =
        useContext(AppUserContext);
    const [beginHealthCareServiceSignup] =
        useBeginHealthServiceSignupMutation();
    const [signInMutation] = useAuthenticateMutation();
    const [signOutMutation] = useLogoutMutation();
    const [healthServiceSignup] = useHealthServiceSignupMutation();

    const signIn = async (emailAddress: string, password: string) => {
        try {
            const result = await signInMutation({
                variables: {
                    email: emailAddress,
                    password: password,
                },
            });

            setCookie(COOKIE_KEY, result.data.authenticate.token);
            setAuthToken(result.data.authenticate.token);
        } catch (error) {
            throw {
                code: 'NotAuthorizedException',
            };
        }
    };

    const federatedSignIn = (healthService: string): void => {
        // TODO
    };

    const signOut = async (showFullscreenLoading = true) => {
        if (showFullscreenLoading) {
            enableFullscreenLoading({
                title: t('common:loggingOutTitle'),
                text: t('common:loggingOutText'),
            });
        }

        await signOutMutation();

        if (showFullscreenLoading) {
            disableFullscreenLoading();
        }

        setUser(null);
        removeCookie(COOKIE_KEY);
        setAuthToken(null);
    };

    const signUp = async (emailAddress: string): Promise<IUseAuthSignup> => {
        try {
            const { data } = await beginHealthCareServiceSignup({
                variables: {
                    email: emailAddress,
                },
            });

            if (!data.beginHealthServiceSignup) {
                return {
                    success: false,
                    errorCode: 'UsernameExistsException',
                };
            }

            return {
                success: true,
            };
        } catch (error) {
            if (error.networkError.statusCode === 422) {
                return {
                    success: false,
                    errorCode: 'UsernameExistsException',
                };
            }

            return {
                success: false,
                errorCode: 'GenericExeption',
            };
        }
    };

    const completeSignUp = async (input: HealthServiceSignupInput) => {
        const result = await healthServiceSignup({
            variables: {
                input: input,
            },
        });

        setCookie(COOKIE_KEY, result.data.healthServiceSignup.token);
        setAuthToken(result.data.healthServiceSignup.token);
    };

    return {
        signIn,
        federatedSignIn,
        signOut,
        signUp,
        completeSignUp,
    };
};

export const useChangePassword = (): ((
    oldPassword: string,
    newPassword: string,
) => Promise<void>) => {
    const [changePasswordMutation] = useChangePasswordMutation();

    return async (oldPassword: string, newPassword: string) => {
        await changePasswordMutation({
            variables: {
                input: {
                    oldPassword,
                    newPassword,
                },
            },
        });
    };
};

export const useChangeEmail = (): ((email: string) => Promise<boolean>) => {
    const [changeEmailMutation] = useChangeEmailMutation();

    return async (email: string): Promise<boolean> => {
        const result = await changeEmailMutation({
            variables: {
                email,
            },
        });

        return result.data.changeCustomerEmail;
    };
};

export const useChangeSmsNumber = (): ((
    smsNumber: E164Number,
) => Promise<boolean>) => {
    const [changSmsNumberMutation] = useChangeSmsNumberMutation();

    return async (smsNumber: E164Number) => {
        const result = await changSmsNumberMutation({
            variables: {
                smsNumber: smsNumber as string,
            },
        });

        return result.data.updateCustomerDetails;
    };
};

export const useVerifySmsPhoneNumber = (): ((
    newSmsPhoneNumber: E164Number,
    code: string,
) => Promise<boolean>) => {
    const [verifySmsPhoneNumberMutation] = useVerifySmsPhoneNumberMutation();

    return async (smsNumber: E164Number, code: string) => {
        const result = await verifySmsPhoneNumberMutation({
            variables: {
                input: {
                    smsNumber: smsNumber as string,
                    code,
                },
            },
        });

        return result.data.verifySmsPhoneNumber;
    };
};

export const useResendSmsVerificationCode = (): ((
    smsPhoneNumber: E164Number,
) => Promise<boolean>) => {
    const [resentVerificationSms] = useResendVerificationSmsMutation();

    return async (smsPhoneNumber: E164Number) => {
        const result = await resentVerificationSms({
            variables: {
                input: {
                    smsPhoneNumber: smsPhoneNumber as string,
                },
            },
        });

        return result.data.resendVerificationSms;
    };
};
