import React from 'react';
import { FC } from 'react';
import Loading from './Loading';

export type ButtonStyle =
    | 'primary'
    | 'secondary'
    | 'primary-alt'
    | 'secondary-alt'
    | 'danger'
    | 'text';

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
    onClick?: () => void;
    isLoading?: boolean;
    isDisabled?: boolean;
    kind?: 'text' | 'button';
    styledAs?: ButtonStyle;
    children: React.ReactNode;
    darkLoadingSpinner?: boolean;
    center?: boolean;
    fullWidth?: boolean;
    noMargin?: boolean;
    type?: 'button' | 'submit' | 'reset';
    btnSize?: 'normal' | 'small';
}

const Button: FC<ButtonProps> = ({
    className = '',
    onClick,
    isLoading = false,
    isDisabled,
    kind = 'button',
    styledAs = 'primary',
    children,
    darkLoadingSpinner,
    center = false,
    fullWidth = true,
    noMargin = false,
    type = 'button',
    btnSize = 'normal',
}) => {
    if (kind === 'text') {
        return (
            <span className={`relative ${className}`}>
                <button
                    onClick={onClick}
                    className={`${className}
                        ${
                            isLoading
                                ? 'focus:outline-none'
                                : 'text-primary-600 font-semibold'
                        }

                        ${
                            isLoading
                                ? 'pr-button-loading-right'
                                : 'delay-button'
                        }

                        ${btnSize === 'small' ? 'text-2xsm-compact' : ''}

                        ${
                            styledAs === 'primary' || styledAs === 'primary-alt' // primary
                                ? 'text-primary-500'
                                : ''
                        }

                        ${
                            styledAs === 'secondary' ||
                            styledAs === 'secondary-alt' // secondary
                                ? 'text-secondary-600'
                                : ''
                        }

                        ${
                            styledAs === 'danger' // danger
                                ? 'text-danger-500'
                                : ''
                        }
                    `}
                    type={type}
                >
                    <div
                        className={`ml-xsm1 w-button-icon inline-block transition-all duration-100 absolute -top-2 right-18
                            ${isLoading ? 'opacity-1 delay-100' : 'opacity-0'}
                        `}
                    >
                        <Loading dark />
                    </div>
                    {children}
                </button>
            </span>
        );
    }

    return (
        <button
            className={`${className} relative rounded-button leading-button focus:outline-none transition-button duration-100

                ${btnSize === 'normal' ? 'py-sm2 px-sm3 text-xsm-compact' : ''}
                ${
                    btnSize === 'small'
                        ? 'py-base2 px-sm1 text-3xsm-compact'
                        : ''
                }

                ${
                    !isLoading && !isDisabled
                        ? 'transform active:scale-button'
                        : ''
                }

                ${isLoading ? 'pr-button-loading-right' : 'delay-button'}

                ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}

                ${
                    styledAs === 'primary' && !isDisabled // primary
                        ? 'border border-primary-500 hover:border-primary-600 text-primary-100 shadow-button-primary bg-primary-500 hover:bg-primary-600 focus:bg-primary-400'
                        : ''
                }

                ${
                    styledAs === 'primary' && isDisabled // primary (disabled)
                        ? 'border border-primary-200 text-primary-100 shadow-button-primary bg-primary-200'
                        : ''
                }

                ${
                    styledAs === 'primary-alt' && !isDisabled // alternate primary
                        ? 'border text-primary-600 border-primary-600 bg-neutral-100 hover:text-primary-100 hover:border-primary-500 hover:bg-primary-500'
                        : ''
                }

                ${
                    styledAs === 'primary-alt' && isDisabled // alternate primary
                        ? 'border text-primary-100 border-primary-100'
                        : ''
                }

                ${
                    styledAs === 'secondary' && !isDisabled // secondary
                        ? 'border border-secondary-600 hover:border-secondary-500 text-secondary-100 shadow-button-primary bg-secondary-600 hover:bg-secondary-500'
                        : ''
                }

                ${
                    styledAs === 'secondary' && isDisabled // secondary disabled
                        ? 'border border-secondary-200 text-secondary-100 shadow-button-primary bg-secondary-200'
                        : ''
                }

                ${
                    styledAs === 'secondary-alt' && !isDisabled // alternate secondary
                        ? 'border border-secondary-600 text-secondary-600 bg-neutral-100 hover:bg-secondary-500 hover:text-secondary-100 hover:border-secondary-500'
                        : ''
                }

                ${
                    styledAs === 'secondary-alt' && isDisabled // alternate secondary disabled
                        ? 'border text-secondary-100 bg-secondary-200'
                        : ''
                }

                ${
                    styledAs === 'danger' && !isDisabled // danger
                        ? 'border border-danger-500 hover:border-danger-600 text-danger-100 bg-danger-500 hover:bg-danger-600 focus:bg-danger-400'
                        : ''
                }

                ${
                    styledAs === 'danger' && isDisabled // danger
                        ? 'border border-danger-200 text-danger-100 bg-danger-200'
                        : ''
                }

                ${center ? 'mx-auto' : ''}

                ${fullWidth ? 'w-full' : ''}

                ${noMargin ? '' : 'mb-md'}
            `}
            disabled={isLoading || isDisabled}
            onClick={onClick}
            type={type}
        >
            <div
                className={`ml-xsm1 w-button-icon inline-block transition-all duration-100 absolute top-12 right-18
                    ${isLoading ? 'opacity-1 delay-100' : 'opacity-0'}
                `}
            >
                <Loading
                    dark={
                        styledAs === 'primary-alt' ? true : darkLoadingSpinner
                    }
                />
            </div>
            <span>{children}</span>
        </button>
    );
};

export default React.memo(Button);
